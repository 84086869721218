import { Injectable } from '@angular/core';
import { Repository } from './repository';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Pagination } from './models/filter';
import { ConversationHistoryCondition } from './models/conversation-history-condition';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ConversationHistoryRepositoryService implements Repository {
  url: string;

  constructor(private httpService: HttpClient) {
    this.url = '/conversation-history';
  }

  /**
   * Get conversation history with condition
   *
   * @param {Pagination} filter
   * @param {ConversationHistoryCondition} conversationHistoryCondition
   * @returns
   * @memberof ConversationHistoryRepositoryService
   */
  // tslint:disable-next-line:max-line-length
  getConversationHistory(
    filter: Pagination,
    conversationHistoryCondition: ConversationHistoryCondition
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    this.url = '/conversation-history';
    const params = new HttpParams().append('page', filter.page.toString()).append('size', filter.size.toString());
    const requestBody = {};
    requestBody['agent_id'] = conversationHistoryCondition.agentId;
    requestBody['start_date'] = conversationHistoryCondition.startDate;
    requestBody['end_date'] = conversationHistoryCondition.endDate;
    return this.httpService.post(this.url, requestBody, { params: params });
  }

  exportData(conversationHistoryCondition: ConversationHistoryCondition, options?: any): Observable<any> {
    this.url = '/report-generation';
    const requestBody = {};
    requestBody['agent_id'] = conversationHistoryCondition.agentId;
    requestBody['start_date'] = conversationHistoryCondition.startDate;
    requestBody['end_date'] = conversationHistoryCondition.endDate;
    return this.httpService.post(this.url, requestBody, options);
  }

  /**
   * Get message information by conversation ID
   *
   * @param {string} conversationId
   * @returns {Observable<any>}
   * @memberof ConversationHistoryRepositoryService
   */
  getMessageById(conversationId: string): Observable<any> {
    this.url = '/conversations';
    return this.httpService.get(this.url + '/' + conversationId + '/messages');
  }

  /**
   * Get all agents from DB
   *
   * @returns {Observable<any>}
   * @memberof ConversationHistoryRepositoryService
   */
  getAllAgent(): Observable<any> {
    this.url = '/agents';
    const params = new HttpParams().append('size', '10000');
    return this.httpService.get(this.url, { params: params });
  }

  getById(id: any): import('rxjs').Observable<any> {
    throw new Error('Method not implemented.');
  }
  add(item: any): import('rxjs').Observable<any> {
    throw new Error('Method not implemented.');
  }
  update(id: any, item: any): import('rxjs').Observable<any> {
    throw new Error('Method not implemented.');
  }
  delete(id: any): import('rxjs').Observable<any> {
    throw new Error('Method not implemented.');
  }
}
