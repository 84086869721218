import { Injectable } from '@angular/core';
import { AgentRepositoryService } from '@app/repository/agent-repository.service';
import { Subject } from 'rxjs';
import { AgentList } from '@app/repository/models/agent-list';
import { AgentResponse } from '@app/repository/models/agent-response';
import { Logger } from '@app/core';
import { ServiceType } from '@app/repository/models/service-type';
import { AgentProfile } from '@app/repository/models/agent-profile';
import { ServiceRepositoryService } from '@app/repository/service-repository.service';
import * as _ from 'lodash';
import { Pagination } from '@app/repository/models/filter';
import { UserRepositoryService } from '@app/repository/user-repository.service';
const log = new Logger('agentService.ts');
@Injectable()
export class AgentService {
  public agentListChanged$: Subject<AgentList[]> = new Subject();
  public agentProfileChanged$: Subject<AgentProfile> = new Subject();
  public agentsChanged$: Subject<AgentProfile[]> = new Subject();
  public agentSkillsChanged$: Subject<ServiceType[]> = new Subject();
  public serviceListChanged$: Subject<ServiceType[]> = new Subject();
  public addedServiceIdSubject: Subject<string> = new Subject();
  public totalAgentChanged$: Subject<number> = new Subject();

  private _totalAgents: number;

  /**
   * _totalAgents Setter.
   *
   * @memberof AgentService
   */
  set totalAgents(total: number) {
    this._totalAgents = total;
    this.totalAgentChanged();
  }

  private next: string;
  private previous: string;

  private agents: AgentProfile[];
  private agentProfile: AgentProfile;
  private agentSkills: ServiceType[];
  private serviceList: ServiceType[];
  private serviceListOrg: ServiceType[];

  constructor(
    private agentRepositoryService: AgentRepositoryService,
    private userRepositoryService: UserRepositoryService,
    private serviceRepositoryService: ServiceRepositoryService
  ) {
    this.agents = [];
    this.agentSkills = [];
    this.serviceList = [];
    this.serviceListOrg = [];
    this.agentProfile = {} as AgentProfile;
  }

  getAllAgents(filter: Pagination) {
    this.agentRepositoryService.all(filter).subscribe((res: AgentResponse) => {
      this.totalAgents = res.count;
      this.totalAgentChanged();
      // this.previous = res.previous;
      // this.next = res.next;

      this.agents = res.results.map(agentData => {
        const serviceNameArr = agentData.services.map(service => {
          return service.service_type.service_name;
        });
        agentData.serviceName = serviceNameArr.join(',');
        return agentData;
      });

      this.agentsChanged();
    });
  }

  getAgentById(id: string) {
    this.userRepositoryService.getById(id).subscribe((res: AgentProfile) => {
      this.agentProfile = { ...res };
      this.agentProfileChanged();
    });
  }

  getAgentSkillById(agent_id: string) {
    this.agentRepositoryService.getAgentSkillById(agent_id).subscribe((res: ServiceType[]) => {
      this.agentSkills = res;
      this.serviceList = _.differenceBy(this.serviceList, this.agentSkills, 'service_name');
      this.agentSkillsChanged();
      this.serviceListChanged();
    });
  }

  updateAgentbyId(id: string, updateKey: string, updateValue: string, imageName: string) {
    this.userRepositoryService.patch(id, updateKey, updateValue, imageName).subscribe((res: AgentProfile) => {
      this.agentProfile = { ...res };
      this.agentProfileChanged();
    });
  }

  getAllService() {
    this.serviceRepositoryService.all().subscribe((res: ServiceType[]) => {
      this.serviceList = res;
      this.serviceListOrg = res;
      this.serviceListChanged();
    });
  }

  // imageUpload(imgData: string) {
  //   this.agentRepositoryService.uploadImage(imgData);
  // }
  addService(agent_id: string, service_data: ServiceType) {
    this.agentRepositoryService.addService(agent_id, service_data.id + '').subscribe((res: any) => {
      const newServiceId = res['data']['id'];
      const addedAgentService = {
        service_name: service_data.service_name,
        id: +newServiceId
      } as ServiceType;
      this.agentSkills.push(addedAgentService);
      this.serviceList = _.differenceBy(this.serviceListOrg, this.agentSkills, 'service_name');
      this.serviceListChanged();
      this.agentSkillsChanged();
    });
  }

  deleteAgentService(service_data: ServiceType) {
    this.agentRepositoryService.deleteAgentSkillById(service_data.id + '').subscribe((res: any) => {
      const deletedServiceId = res['data']['id'];
      const deletedAgentService = {
        service_name: service_data.service_name,
        id: +deletedServiceId
      } as ServiceType;
      const deletedIndex = _.findIndex(this.agentSkills, { id: deletedAgentService.id });
      this.agentSkills.splice(deletedIndex, 1);
      this.serviceList = _.differenceBy(this.serviceListOrg, this.agentSkills, 'service_name');
      this.serviceListChanged();
      this.agentSkillsChanged();
    });
  }

  /**
   * Emit list system service.
   *
   * @private
   * @memberof AgentService
   */
  private serviceListChanged() {
    this.serviceListChanged$.next(this.serviceList.slice());
  }

  /**
   * Emit list agents.
   *
   * @private
   * @memberof AgentService
   */
  private agentsChanged() {
    // log.debug('Agents List changed: ', this.agents);
    this.agentsChanged$.next(this.agents.slice());
  }

  /**
   * Emit Agent Profile.
   *
   * @private
   * @memberof AgentService
   */
  private agentProfileChanged() {
    // tslint:disable-next-line:quotemark
    // log.debug("Agent's Profile changed: ", this.agentProfile);
    this.agentProfileChanged$.next({ ...this.agentProfile });
  }

  /**
   * Emit Agent Skills.
   *
   * @private
   * @memberof AgentService
   */
  private agentSkillsChanged() {
    // tslint:disable-next-line:quotemark
    // log.debug("Agent's Skills changed: ", this.agentSkills);
    this.agentSkillsChanged$.next(this.agentSkills.slice());
  }

  private totalAgentChanged() {
    this.totalAgentChanged$.next(this._totalAgents);
  }
}
